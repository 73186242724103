import React, { Component } from 'react';
import {
  Redirect,
  Route,
  Switch,
  withRouter
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import throttle from 'lodash/throttle';
import Dialog from '@material-ui/core/Dialog';
import Dashboard from 'containers/dashboard/dashboardRedux';
import ItineraryMaker from 'containers/itineraryMaker/itineraryMakerRedux';
// import Inventory from 'containers/inventory/inventoryRedux';
import Auth from 'containers/auth/authRedux';
// import StayList from 'containers/stayList/stayListRedux';
// import SupplierList from 'containers/supplierList/supplierListRedux';
// import Experiences from 'containers/experiences/experiencesRedux';
// import ExperienceDetail from 'containers/experienceDetail/experienceDetailRedux';
// import StayDetail from 'containers/stayDetail/stayDetailRedux';
// import RoomDetail from 'containers/roomDetail/roomDetailRedux';
// import SupplierDetail from 'containers/supplierDetail/supplierDetailRedux';
// import HotelDetail from 'containers/hotelDetail/hotelDetailRedux';
// import HotelList from 'containers/hotelList/hotelListRedux';
// import PlaceList from 'containers/placeList/placeListRedux';
// import PlaceDetail from 'containers/placeDetail/placeDetailRedux';
import {
  deleteCookies, getCookies, headerGenerator, isEqual, setCookie
} from 'utils/common';
import { ROLES } from 'utils/consts';
import Loader from 'UI/loader';
import Navbar from 'UI/navbar';
import Snackbar from 'UI/snackbar';
import InactiveCard from 'UI/inactiveCard';
import OfflineNotifier from 'UI/offlineNotifier';
import { getParams, pingUser, registerNotifToken } from 'containers/common/commonActions';
import { getExpertDetails, logout, updateToken } from 'containers/auth/authActions';
import { getNotifications } from 'containers/notification/notificationActions';
import NotificationPermission from 'UI/notificationPermission';
import PerfModal from 'components/perfModal/perfModal';
import RequestPackModal from 'components/requestPackModal/requestPackModal';
import TravClanQuoteModal from 'components/dashboard/travClanQuoteModal';
import theme from './theme';
import TemplatesIndex from './containers/templates/templatesRedux';

// const METABASE_SITE_URL = 'https://meta.flynote.com';
// const METABASE_SECRET_KEY = '123';

// eslint-disable-next-line
// const LazyLoad = ({ Component, ...rest }) => (
//   <Suspense fallback={<div className="loader"><Loader /></div>}>
//     <Component {...rest} />
//   </Suspense>
// );

// const LazyMaker = React.lazy(() => import('./containers/itineraryMaker/itineraryMakerRedux'));
// const LazyInventory = React.lazy(() => import('./containers/inventory/inventoryRedux'));

class App extends Component {
  constructor(props) {
    super(props);
    const { getExpertDetails: getExpDetails } = this.props;
    this.poller = null;
    this.notifPoller = null;
    this.headers = headerGenerator('');
    const resp = getCookies();
    let loggedIn = false;
    if (resp.token) {
      loggedIn = true;
      this.headers = headerGenerator(resp.token);
      this.setExpert();
      getExpDetails({}, this.headers);
      this.pollHandler();
      this.poller = setInterval(() => this.pollHandler(), 30000);
      this.notifPoller = setInterval(() => this.notifPollHandler(), 120000);
    }
    const isOffline = !navigator.onLine;
    this.state = {
      loggedIn,
      open: false,
      msg: '',
      variant: 'info',
      actions: [],
      timeoutDialog: false,
      notifPopup: false,
      feedbackDialog: false,
      error: null,
      errorInfo: '',
      offlineSnack: isOffline,
      showPerfModal: null,
      showRequestPackModal: (new URLSearchParams(window.location.search)).get('orpm') === 'true',
      travClanQuoteDialog: false,
    };
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', this.workerMsgHandler);
    }
  }

  // componentDidCatch = (error, errorInfo) => {
  //   this.setState({ error, errorInfo });
  // }

  componentDidUpdate = (prevProps) => {
    const {
      loginResp,
      history,
      notifications,
      unReadCount,
      getExpertDetailsError,
    } = this.props;
    const { showPerfModal } = this.state;
    const { expert } = loginResp || {};
    if (expert
      && !expert.roles.includes(ROLES.TEAM_LEAD)
      && window.location.href.includes('dashboard')
      && showPerfModal === null
      && window.localStorage.getItem('showPerfModal') !== moment().format('YYYY-MM-DD')) {
      this.setState({ showPerfModal: true });
      window.localStorage.setItem('showPerfModal', moment().format('YYYY-MM-DD'));
    }
    if (!isEqual(loginResp, prevProps.loginResp) && loginResp.token) {
      if (window.location.href.includes('login')) {
        history.replace('/');
      }
      this.setState({ loggedIn: true });
      setCookie({ token: loginResp.token });
      window.localStorage.setItem('expert', JSON.stringify(loginResp.expert));
      this.headers = headerGenerator(loginResp.token);
      this.postLoginHandler();
      if (!this.poller) {
        this.pollHandler();
        this.poller = setInterval(() => this.pollHandler(), 30000);
      }
      if (this.notifPoller) {
        this.notifPollHandler();
        this.notifPoller = setInterval(() => this.notifPollHandler(), 120000);
      }
    }
    if (!isEqual(notifications, prevProps.notifications) && notifications.length
      && prevProps.notifications.length) {
      this.showSnackbar('You have a new notification', 'success');
    }
    if (!isEqual(unReadCount, prevProps.unReadCount) && unReadCount) {
      this.showSnackbar('You have a new notification', 'success');
    }
    if (!isEqual(getExpertDetailsError, prevProps.getExpertDetailsError) && getExpertDetailsError) {
      this.showSnackbar('Error getting expert', 'error');
      this.logOutHandler();
    }
  };

  setExpert = () => {
    const { updateToken: updateTkn } = this.props;
    const expert = window.localStorage.getItem('expert');
    if (expert) {
      try {
        updateTkn({ ...getCookies(), expert: JSON.parse(expert) });
      } catch (err) {
        this.logOutHandler();
      }
    } else {
      this.logOutHandler();
    }
  };

  postLoginHandler = () => {
    const { getParamsMeta, getNotifs } = this.props;
    this.checkNotifPermission();
    this.idleTimer = setTimeout(this.toggleActiveUser, 30000);
    document.addEventListener('mousemove', this.resetTimer);
    document.addEventListener('keypress', this.resetTimer);
    getParamsMeta({}, this.headers);
    getNotifs({
      skip: 0,
      limit: 15,
    }, this.headers);
    window.addEventListener('offline', () => { this.setState({ offlineSnack: true }); });
    window.addEventListener('online', () => { this.setState({ offlineSnack: false }); });
    // if (!window.origin.includes('localhost')) {
    //   window.onbeforeunload = (e) => {
    //     e.preventDefault();
    //     e.returnValue = 'Are you sure, want to quit?';
    //   };
    // }
  };

  checkNotifPermission = () => {
    const currPermission = Notification.permission;
    if (currPermission !== 'granted' && !window.origin.includes('localhost')) {
      Notification.requestPermission()
        .then((newPermission) => {
          if (newPermission === 'granted') {
            this.showSnackbar('Great, you will receive notifications now', 'success');
          } else {
            this.showSnackbar('Please enable notifications to receive trip & request updates', 'warning');
          }
        });
    }
  };

  workerMsgHandler = (evt) => {
    const { registerToken } = this.props;
    if (evt.data.notificationToken) {
      registerToken(evt.data, this.headers);
    } else if (evt.data['firebase-messaging-msg-data']) {
      const { notification } = evt.data['firebase-messaging-msg-data'];
      // eslint-disable-next-line no-new
      new Notification(notification.title,
        {
          body: notification.body,
          icon: notification.icon,
          vibrate: [300],
        });
    } else if (evt.data.update) {
      this.showSnackbar('Update is available', 'info', [{
        title: 'UPDATE',
        action: () => {
          window.onbeforeunload = undefined;
          window.location.reload();
        },
      }]);
    }
  };

  logOutHandler = () => {
    const { history, logOut } = this.props;
    this.setState({ loggedIn: false });
    clearInterval(this.poller);
    clearInterval(this.notifPoller);
    clearInterval(this.idleTimer);
    deleteCookies(['token', 'message', 'state']);
    window.localStorage.clear();
    if (caches) {
      caches.keys()
        .then((cacheNames) => {
          for (let i = 0; i < cacheNames.length; i++) {
            if (cacheNames[i] !== 'google-fonts') {
              caches.delete(cacheNames[i]);
            }
          }
        });
    }
    window.onbeforeunload = undefined;
    history.replace('/login');
    window.location.reload();
    logOut({}, this.headers);
  };

  pollHandler = () => {
    const { pingUser: pngUser } = this.props;
    if (!window.origin.includes('localhost')) {
      pngUser({}, this.headers);
    }
  };

  notifPollHandler = () => {
    const { getNotifs } = this.props;
    if (!window.origin.includes('localhost')) {
      getNotifs({
        skip: 0,
        limit: 15,
      }, this.headers);
    }
  };

  toggleState = (field) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }));
  };

  // eslint-disable-next-line
  resetTimer = throttle(() => {
    clearTimeout(this.idleTimer);
    this.idleTimer = setTimeout(() => this.toggleActiveUser(true), 30000);
    const activeTime = moment().toISOString();
    localStorage.setItem('lastActive', activeTime);
  }, 2000, { leading: true });

  showSnackbar = (msg, variant = 'info', actions = [], duration = 10000) => {
    this.setState({
      open: true,
      msg,
      variant,
      actions,
    });
    setTimeout(() => this.onClose(), duration);
  };

  onClose = () => {
    this.setState({
      open: false,
      actions: [],
    });
  };

  toggleActiveUser = (active) => {
    if (active) {
      if (this.poller) {
        clearInterval(this.poller);
      }
      if (this.notifPoller) {
        clearInterval(this.notifPoller);
      }
      this.poller = setInterval(() => this.pollHandler(), 30000);
      this.notifPoller = setInterval(() => this.pollHandler(), 120000);
      this.setState({ timeoutDialog: false });
    } else {
      const lastActiveTime = localStorage.getItem('lastActive');
      if (!lastActiveTime || (moment().unix() - moment(lastActiveTime).unix()) > 30) {
        clearInterval(this.poller);
        clearInterval(this.notifPoller);
        this.setState({ timeoutDialog: true });
      }
    }
  };

  actionHandler = (action) => {
    if (action === 'feedback') {
      this.toggleState('feedbackDialog');
    } else if (action === 'logout') {
      this.logOutHandler();
    }
  };

  togglePerfModal = () => {
    this.toggleState('showPerfModal');
  };

  toggleRequestPackModal = () => {
    this.toggleState('showRequestPackModal');
  };

  render() {
    const { loginResp, isLogging, paramsMeta = {} } = this.props;
    const { travClanUrl } = paramsMeta;
    console.log('found ', travClanUrl);
    const {
      loggedIn,
      open,
      msg,
      variant,
      actions,
      timeoutDialog,
      notifPopup,
      showPerfModal,
      showRequestPackModal,
      error,
      errorInfo,
      offlineSnack,
      travClanQuoteDialog,
    } = this.state;
    const { expert = {} } = loginResp;
    if (error && error.toString()) {
      return (
        <>
          <h2>
            Something unexpected has happened, which caused this crash,
            <br />
            please contact tech team
            <span aria-label="sad" role="img">😢</span>
          </h2>
          <p>
            {errorInfo.componentStack}
          </p>
          <br />
          <p>{error}</p>
        </>
      );
    }
    if (isLogging || (loggedIn && !expert?._id)) {
      // if loggedIn, wait till expert roles are loaded
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    }

    let routes;
    /* eslint-disable max-len */
    // const inventoryRoutes = (
    //   <>
    //     <Route
    //       exact
    //       path="/admin/inventory"
    //       render={() => <Inventory showSnackbar={this.showSnackbar} />}
    //     />
    //     <Route exact path="/admin/inventory/experiences" render={() => <Experiences />} />
    //     <Route exact path="/admin/inventory/supplierList" render={() => <SupplierList showSnackbar={this.showSnackbar} />} />
    //     <Route path="/admin/inventory/experienceDetail/:experienceId" render={() => <ExperienceDetail />} />
    //     <Route path="/admin/inventory/supplierDetail/:supplierId" render={() => <SupplierDetail showSnackbar={this.showSnackbar} />} />
    //     <Route exact path="/admin/inventory/stayList" render={() => <StayList showSnackbar={this.showSnackbar} />} />
    //     <Route path="/admin/inventory/hotelDetail/:hotelId" render={() => <HotelDetail showSnackbar={this.showSnackbar} />} />
    //     <Route exact path="/admin/inventory/hotelList" render={() => <HotelList showSnackbar={this.showSnackbar} />} />
    //     <Route path="/admin/inventory/stayDetail/:stayId/rooms" render={() => <RoomDetail showSnackbar={this.showSnackbar} />} />
    //     <Route path="/admin/inventory/stayDetail/:stayId" render={() => <StayDetail showSnackbar={this.showSnackbar} />} />
    //     <Route exact path="/admin/inventory/placeList" render={() => <PlaceList showSnackbar={this.showSnackbar} />} />
    //     <Route path="/admin/inventory/placeDetail/:placeId" render={() => <PlaceDetail showSnackbar={this.showSnackbar} />} />
    //   </>
    // );
    // const payload = {
    //   resource: { dashboard: 183 },
    //   params: {
    //     email: expert.email,
    //   },
    //   exp: Math.round(Date.now() / 1000) + (24 * 60 * 60), // 24 hour expiration
    // };
    // const token = jwt.sign(payload, METABASE_SECRET_KEY);
    // const iframeUrl = `${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=true&titled=true`;
    if (expert && expert.roles
      && expert.roles.includes(ROLES.INVENTORY_CRUD) && expert.roles.length === 1) {
      routes = null;
    } else {
      routes = (
        <Switch>
          <Route
            exact
            path="/dashboard"
            render={() => <Dashboard showSnackbar={this.showSnackbar} toggleTravClanQuoteModal={() => { this.toggleState('travClanQuoteDialog') }} />}
          />
          <Route
            exact
            path="/request/:usid"
            render={() => <Dashboard showSnackbar={this.showSnackbar} />}
          />
          <Route
            exact
            path="/analytics"
            render={() => {
              return (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    flexDirection: 'column',
                    overflow: 'hidden',
                  }}
                >
                  <iframe
                    title="Meta Dashboard"
                    src={expert.iframeUrl}
                    frameBorder="0"
                    height="600"
                    allowtransparency
                  />
                </div>
              );
            }}
          />
          {/* <Route exact path="/" render={() => <Dashboard showSnackbar={this.showSnackbar} />} /> */}
          <Route
            path="/itineraryMaker"
            render={() => <ItineraryMaker showSnackbar={this.showSnackbar} />}
          />
          {
            <Route
              path="/templates"
              render={() => <TemplatesIndex showSnackbar={this.showSnackbar} />}
            />
          }
          {
            expert?.roles?.includes(ROLES.TEAM_LEAD)
              ? <Redirect from="/" to="/analytics" />
              : <Redirect from="/" to="/dashboard" />
          }
          {/* {expert?.roles
            && (expert.roles.includes(ROLES.INVENTORY_CRUD) || expert.roles.includes(ROLES.ADMIN))
            ? inventoryRoutes : null} */}
        </Switch>
      );
    }
    return (
      <ThemeProvider theme={theme}>
        <>
          <Dialog
            open={timeoutDialog}
            onClose={() => this.toggleState('timeoutDialog')}
          >
            <InactiveCard
              onDismiss={() => this.toggleState('timeoutDialog')}
              onActive={() => this.toggleActiveUser(true)}
            />
          </Dialog>
          <Dialog open={notifPopup}>
            <NotificationPermission
              onDismiss={() => this.toggleState('notifPopup')}
              onAllow={() => this.checkNotifPermission()}
            />
          </Dialog>
          {
            expert
              ? (
                <Dialog
                  open={showPerfModal}
                  maxWidth="xl"
                  onClose={this.togglePerfModal}
                >
                  <PerfModal
                    togglePerfModal={this.togglePerfModal}
                    toggleRequestPackModal={this.toggleRequestPackModal}
                    expert={expert}
                  />
                </Dialog>
              ) : null
          }
          {
            expert
              ? (
                <Dialog
                  open={showRequestPackModal}
                  maxWidth="xl"
                  onClose={this.toggleRequestPackModal}
                >
                  <RequestPackModal
                    toggleRequestPackModal={this.toggleRequestPackModal}
                    expert={expert}
                    showSnackbar={this.showSnackbar}
                    showRequestPackModal={showRequestPackModal}
                  />
                </Dialog>
              ) : null
          }
          <Dialog
            open={travClanQuoteDialog}
            onClose={() => this.toggleState('travClanQuoteDialog')}
            maxWidth="xl"
            aria-labelledby="trav clan quote"
          >
            <TravClanQuoteModal
              onClose={() => this.toggleState('travClanQuoteDialog')}
              travClanUrl={travClanUrl}
            />
          </Dialog>
          <Navbar
            loggedIn={loggedIn}
            user={expert}
            actionHandler={this.actionHandler}
            togglePerfModal={this.togglePerfModal}
            toggleRequestPackModal={this.toggleRequestPackModal}
            expert={expert}
            toggleTravClanQuoteModal={() => { this.toggleState('travClanQuoteDialog') }}
          />
          {loggedIn ? routes : <Auth showSnackbar={this.showSnackbar} />}
          <Snackbar
            open={open}
            actions={actions}
            message={msg}
            variant={variant}
            onClose={this.onClose}
          />
          <OfflineNotifier
            open={offlineSnack}
            handleClose={() => this.toggleState('offlineSnack')}
          />
        </>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.common,
  notifications: state.notification.rawNotifications,
  unReadCount: state.notification.unReadCount,
});

const mapDispatchToProps = (dispatch) => ({
  updateToken: (params) => dispatch(updateToken(params)),
  logOut: (params, headers) => dispatch(logout(params, headers)),
  registerToken: (params, headers) => dispatch(registerNotifToken(params, headers)),
  pingUser: (params, headers) => dispatch(pingUser(params, headers)),
  getParamsMeta: (params, headers) => dispatch(getParams(params, headers)),
  getNotifs: (params, headers) => dispatch(getNotifications(params, headers)),
  getExpertDetails: (params, headers) => dispatch(getExpertDetails(params, headers)),
});

App.propTypes = {
  classes: PropTypes.object,
  logOut: PropTypes.func,
  loginResp: PropTypes.object,
  isLogging: PropTypes.bool,
  pingUser: PropTypes.func,
  updateToken: PropTypes.func,
  getParamsMeta: PropTypes.func,
  getNotifs: PropTypes.func,
  registerToken: PropTypes.func,
  history: PropTypes.object,
  notifications: PropTypes.array,
  getExpertDetails: PropTypes.func,
  getExpertDetailsError: PropTypes.bool,
  unReadCount: PropTypes.number,
  paramsMeta: PropTypes.object,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App));
