import withStyles from '@material-ui/styles/withStyles';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { hot } from 'react-hot-loader/root';

class TravClanQuoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { travClanUrl, classes } = this.props;
    return (
      <div className={classes.container}>
        <iframe
          title="travClanQuoteModal"
          src={travClanUrl}
          style={{ height: '600px', width: '100%' }}
        />
      </div>
    );
  }
}

const styles = () => ({
  container: {
    flex: 1,
    flexDirection: 'column',
    width: 900,
  },
  body: {
    padding: 40,
    flex: 1,
    flexDirection: 'column',
  },
  input: {
    height: 64,
  },
});

TravClanQuoteModal.propTypes = {
  classes: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  travClanUrl: PropTypes.string.isRequired,
};

export default hot(withStyles(styles)(TravClanQuoteModal));
